import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Store from '../../helpers/Store';
import Table from '../../screens/tableGruposDocs';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import Nav from '../../screens/nav';
import { faWindowClose,faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';

const  queryStringParams = queryString.parse(window.location.search);

let id=0

let colores =   {
                  Asistencia:"bg-primary text-white",
                  Inasistencia:"bg-danger text-white",
                  Excusa:"bg-warning",
                  Incapacidad:"bg-dark text-white",
                  Otros:"bg-light",
                }


let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]

let meses =   [
                {
                  label:"Febrero",
                  value:"02",
                },
                {
                  label:"Marzo",
                  value:"03",
                },
                {
                  label:"Abril",
                  value:"04",
                },
                {
                  label:"Mayo",
                  value:"05",
                },
                {
                  label:"Junio",
                  value:"06",
                },
                {
                  label:"Julio",
                  value:"07",
                },
                {
                  label:"Agosto",
                  value:"08",
                },
                {
                  label:"Septiembre",
                  value:"09",
                },
                {
                  label:"Octubre",
                  value:"10",
                },
                {
                  label:"Noviembre",
                  value:"11",
                },
                {
                  label:"Diciembre",
                  value:"12",
                },
              ]

const Form=(props)=>{
  const [active, setActive] = useState(false);
  const [activeFecha, setActiveFecha] = useState(false);
  const [activeEdit, setActiveEdit] = useState(false);

  const getList=(fecha)=>{
    setActiveFecha(fecha)
    let data        =   {
                          token:queryStringParams.token,
                          fecha:fecha,
                        }
    Functions.PostAsync("Puente","gruposProfesores",data,{},{name:"callbackList",funct:callbackList})
  }

  const callbackList=(data)=>{
    if (data.response.asistencias) {
      props.setAsistencias(data.response.asistencias)
    }
  }

  const editAsistencia=(e,row,row2)=>{
    props.Fasistencia(e,row,row2);
    getRangoFechas(props.open.grupo_id)
  }

  const getRangoFechas=(token)=>{
    let data            =   {token:queryStringParams.token}
        data.grupo_id   =   token
        data.mes        =   props.mes
    Functions.PostAsync("Puente","getIntervalFechas",data,{},{name:"callbackRangoFechas",funct:callbackRangoFechas})
  }

  const callbackRangoFechas=(data)=>{
    if (data.response.data) {
      props.setRangoFechas(data.response.data)
    }
    if (data.response.asistencias) {
      props.setAsistencias(data.response.asistencias)
    }
    setActiveEdit(false)
  }

  return  <>
            <form onSubmit={props.onSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-6 mb-3">
                    <FontAwesomeIcon className="cursor-pointer mr-2" icon={faWindowClose} onClick={()=>props.setOpen(false)}/>
                    Lista de alumnos
                  </div>
                  {meses?<>
                    <div className={props.lista.length>0 && props.rangoFechas.length>0?"col-12 col-sm-3 text-right":"col-12 col-sm-6 text-right"}>
                      <Select
                        required={true}
                        data={meses}
                        name="mes"
                        selectDefault="mes entrenamiento"
                        onChange={(e)=>{props.setMes(e.target.value)}}
                      />
                    </div>
                  </>:false}
                  {props.lista.length>0 && props.rangoFechas.length>0?<div className="col-12 col-sm-3 pb-2">
                    <a href={Config.ConfigApirest+"PDF/asistenciaEnBlanco?token="+queryStringParams.token+"&fecha="+activeFecha+"&gid="+props.open.grupo_id} className="btn btn-primary mr-2 btn-block">
                      Exportar listado en blanco
                    </a>
                  </div>:false}
                </div>
                <div className="row">
                  <div className="col">
                    {props.lista.length>0 && props.rangoFechas.length>0?<>
                      <div class="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th > Alumno </th>
                              {props.rangoFechas.map((row2,key2)=>{
                                return <th key={key2} style={{fontSize:"14px"}} className="p-1 text-center">
                                  <b>{row2.format}</b>
                                </th>
                              })}
                            </tr>
                          </thead>
                          {props.lista.map((row,key)=>{
                            return <tr key={key}>
                                      <td style={{fontSize:"14px"}}><div style={{width:"250px"}}> {row.nombres} {row.apellidos}</div></td>
                                      {props.rangoFechas.map((row2,key2)=>{
                                        let asistencia  = ""
                                        if (  props.asistencias!==undefined &&
                                              props.asistencias[row.nro_documento]!==undefined &&
                                              props.asistencias[row.nro_documento][row2.value]!==undefined &&
                                              props.asistencias[row.nro_documento][row2.value].estatus!==undefined
                                            ) {
                                              //console.log(colores[props.asistencias[row.nro_documento][row2.value].estatus])
                                              //console.log(props.asistencias[row.nro_documento][row2.value].estatus)
                                              return  <td key={key2} className={colores[props.asistencias[row.nro_documento][row2.value].estatus]!==undefined?"text-center "+colores[props.asistencias[row.nro_documento][row2.value].estatus]+" ":"" }>
                                                        <div onDoubleClick={()=>(Store.get("user").perfil!=="11")?setActiveEdit(key+"-"+key2):false}>
                                                          {activeEdit && activeEdit===key+"-"+key2?<select defaultValue={props.asistencias[row.nro_documento][row2.value].estatus}
                                                                  className="form-control"
                                                                  onChange={(e)=>{editAsistencia(e,row,row2)}}
                                                          >
                                                            <option value="">Seleccione</option>
                                                            <option value="Asistencia">Asistencia</option>
                                                            <option value="Inasistencia">Inasistencia</option>
                                                            <option value="Excusa">Excusa</option>
                                                            <option value="Incapacidad">Incapacidad</option>
                                                            <option value="Otros">Otros</option>
                                                          </select>:<>
                                                            <b>{props.asistencias[row.nro_documento][row2.value].estatus}</b>
                                                          </>}
                                                        </div>
                                                      </td>

                                        }else {
                                          return  <td key={key2} className="text-center" id={key+"_"+key2} >
                                                    <select className="form-control"
                                                            onChange={(e)=>{props.Fasistencia(e,row,row2,key+"_"+key2);}}
                                                      >
                                                      <option value="">Seleccione</option>
                                                      <option value="Asistencia">Asistencia</option>
                                                      <option value="Inasistencia">Inasistencia</option>
                                                      <option value="Excusa">Excusa</option>
                                                      <option value="Incapacidad">Incapacidad</option>
                                                      <option value="Otros">Otros</option>
                                                    </select>
                                                  </td>
                                        }

                                      })}
                                    </tr>
                          })}
                        </table>
                      </div>
                    </>:false}
                  </div>
                </div>
              </div>
            </form>
          </>
}

const App=(props)=>{
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [asistencias, setAsistencias] = useState({});
  const [rangoFechas, setRangoFechas] = useState(false);
  const [mes, setMes] = useState(false);

  /*const getInit=()=>{
    let data        =   {
                          token:queryStringParams.token,
                          fecha:inputs.rangoFechas,
                        }
    Functions.PostAsync("Puente","gruposProfesoresDocumentosAlumnos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setAsistencias(data.response.asistencias)
      setTimeout(function(){
        setData(data.response.data)
        setData2(data.response.grupos)
      }, 1500);
    }
  }*/

  const getRangoFechas=(token)=>{
    let data            =   {token:queryStringParams.token}
        data.grupo_id   =   token
        data.mes        =   mes
    Functions.PostAsync("Puente","getIntervalFechas",data,{},{name:"callbackRangoFechas",funct:callbackRangoFechas})
  }

  const callbackRangoFechas=(data)=>{
    if (data.response.data) {
      setRangoFechas(data.response.data)
    }
    if (data.response.asistencias) {
      setAsistencias(data.response.asistencias)
    }
  }

  /*useEffect(() => {
    getInit()
  },[queryStringParams.token])*/

  /*useEffect(() => {
    getRangoFechas(open.grupo_id)
  },[mes])*/

  useEffect(() => {

    if (!open && mes) {
      setMes(false)
      setRangoFechas(false)
      //getRangoFechas(open.grupo_id)
    }
    if (open.inscritos!==undefined) {
      setLista(open.inscritos)
    }

  },[open])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","asignarProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setAsistencias(data.response.data)
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"consecutivo",
      },
      {
        label:"Grupo",
        value:"grupo_entrenamiento",
      },
      {
        label:"Alumno",
        value:"nombres",
      },
      {
        label:"Nro Documento",
        value:"nro_documento",
      },
      {
        label:"Fecha inicio",
        value:"fecha_inicio",
      },
      {
        label:"Fecha incripción",
        value:"fecha",
      },
      {
        label:"Foto",
        value:"foto",
      },
      {
        label:"Foto Documento",
        value:"foto_cedula",
      },
      {
        label:"EPS",
        value:"foto_eps",
      },
      {
        label:"Valoración médica",
        value:"foto_certificado_medico",
      },
      {
        label:"Certificado Estudios",
        value:"foto_certificado_estudio",
      },
  ]

  const asistenciaF  = (e,row,row2,keys)=>{
    let elemento = document.getElementById(keys);
        elemento.className = "text-center "+colores[e.target.value]
    let data            =   {}
        data.token      =   queryStringParams.token
        data.estatus    =   e.target.value
        data.fecha      =   row2.value
        data.alumno_id  =   row.nro_documento
    //return console.log(data);
    Functions.PostAsync("Puente","asistenciaProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const onChangeOtros  = (e,row)=>{
    let data                =   {}
        data.token          =   queryStringParams.token
        data.otros          =   e.target.value
        data.fecha          =   inputs.rangoFechas
        data.nro_documento  =   row.nro_documento
    Functions.PostAsync("Puente","asistenciaProfesorOtros",data,{})
  }

  const onChangeMes=(e)=>{

  }

  return  <><div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <Nav {...props} user={Store.get("user")}/>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                {!open?<Table setOpen={setOpen} />:false}
              </div>
            </div>
          </div>
          {open?<Form  inputs={inputs}
                    setAsistencias={setAsistencias}
                    setData2={setData2}
                    open={open}
                    onChangeMes={onChangeMes}
                    lista={lista}
                    data2={data2}
                    setOpen={setOpen}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    rangoFechas={rangoFechas}
                    setRangoFechas={setRangoFechas}
                    asistencias={asistencias}
                    Fasistencia={asistenciaF}
                    mes={mes}
                    setMes={setMes}
                    onChangeOtros={onChangeOtros}/>:false
          }
        </>
}
export default App
