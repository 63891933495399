import React,{useState,useEffect} from 'react';
import Input from '../../screens/inputs';
import Select from '../../screens/select';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=()=>{
  const [inputs, setInputs] = useState({});
  const [data, setData] = useState([]);

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  const getInit=()=>{
    let data        =   {
                          token:queryStringParams.token,
                        }
    Functions.PostAsync("Puente","gruposEntrenamientos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
  }

  useEffect(() => {
    getInit()
  },[])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
    Functions.PostAsync("Puente","GuardarPartido",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    document.location.href=Config.ConfigAppUrl+"sadminc/estadisticas/listadopartidos?token="+queryStringParams.token
  }


  return <form className="container-fluid" onSubmit={onSubmit}>
            <a href={Config.ConfigAppUrl+"sadminc/estadisticas/partidos?token="+queryStringParams.token} className="btn btn-secondary text-right mr-2">Volver atrás</a>
            <div className="row pt-2 mt-1">
              <div className="col-12 mb-3">
                <div className=" card ">
                  <div className=" card-header ">
                    Datos del grupo
                  </div>
                  <div className=" card-body ">
                    <div className="row">
                      <div className="col">
                        <Input
                          className="form-control"
                          defaultValue={inputs.fecha}
                          required={true}
                          type={"date"}
                          data={inputs}
                          name="fecha"
                          placeholder="Fecha"
                          title="Fecha"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col">
                        <Input
                          className="form-control"
                          defaultValue={inputs.contendor}
                          required={true}
                          data={inputs}
                          name="contendor"
                          placeholder="Contendor"
                          title="Contendor"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col">
                        <Select
                          defaultValue={inputs.grupo_id}
                          required={true}
                          data={data}
                          name="grupo_entrenamiento_id"
                          title="Grupo de Entrenamiento"
                          selectDefault="Seleccione grupo de entrenamiento"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col pt-4  ">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
}
export default App
