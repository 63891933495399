import { BrowserRouter, Route, Switch  } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import Encuestas from '../encuestas';
import Escuela from '../escuela';
import Gestion from '../gestion';
import Partidos from '../partidos';
import Estadisticas from '../estadisticas';
import Seguridad from '../seguridad';

import Nav from '../../screens/nav';

const App=(props)=>{
  return  <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <Nav {...props}/>
              </div>
            </div>
            <StateContext.Provider value={{}}>
              <BrowserRouter>
                <Switch>
                  <Route exact path={"/"} render={()=><div></div>}/>
                  <Route exact path={"/sadminc/encuestas*"} render={()=><Encuestas/>}/>
                  <Route exact path={"/sadminc/escuela*"} render={()=><Escuela/>}/>
                  <Route exact path={"/sadminc/gestion*"} render={()=><Gestion/>}/>
                  <Route exact path={"/sadminc/partidos*"} render={()=><Partidos/>}/>
                  <Route exact path={"/sadminc/estadisticas*"} render={()=><Estadisticas/>}/>
                  <Route exact path={"/sadminc/seguridad*"} render={()=><Seguridad/>}/>
                </Switch>
              </BrowserRouter>
            </StateContext.Provider>
          </div>
}

export default App;
