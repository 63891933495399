import React,{useState,useEffect} from 'react';
import Table from 'react-bootstrap/Table'
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//let pagina_data = 1
//let totalrows=0
let cantidad_por_paginas  = 5

const App=(props)=>{

  let   data                        =   props.data
  //const [result, setResult]         =   useState([]);
  //const [totalrows, setTotalrows]   =   useState(0);
  const [pagina, setPagina]         =   useState(0);
  const [paginas, setPaginas]       =   useState([]);

  useEffect(() => {
    paginacion(data);
    setPagina(0);
  },[props.data])

  const paginacion=(data)=>{
    let resultado = []
    const LONGITUD_PEDAZOS = cantidad_por_paginas;
    for (let i = 0; i < data.length; i += LONGITUD_PEDAZOS) {
      let pedazo = data.slice(i, i + LONGITUD_PEDAZOS);
      resultado.push(pedazo);
    }
    setPaginas(resultado);
  }

  //console.log(paginas);

  return  <>
            <div className="min-height-350">
              <Table striped bordered hover>
              {props.thead!==undefined? <thead><tr>
                                          {props.thead.map((row,key)=>{
                                            return <th key={key}>{row.label}</th>
                                          })}
                                        </tr></thead>:<></>}
              {props.thead!==undefined && paginas.length>0? <tbody>
                                          {
                                            paginas[pagina].map((row,key)=>{
                                              return  <tr key={key}>
                                                        {props.thead.map((row2,key2)=>{
                                                          if (key2===0) {
                                                            return <td width="50" className="text-center" key={key2}>{(key + 1)}</td>
                                                          }else if (key2>0 && row2.value!=='editar') {
                                                            return <td key={key2}>{row[row2.value]}</td>
                                                          }else {
                                                            return  <td width="150" className="text-center" key={key2}>
                                                                      {row2.extra!==undefined && row2.extra!==false?<>{row2.extra.map((row3,key3)=>{
                                                                                                  return <a key={key3} target={row3.target} className="mr-2" href={row3.url+row.token}>{row3.label}</a>
                                                                                                })}
                                                                                              </>:<></>}
                                                                      <FontAwesomeIcon className="cursor-pointer" icon={faEdit} onClick={()=>props.setOpen(row)}/>
                                                                    </td>
                                                          }
                                                        })}
                                                      </tr>
                                            })
                                          }

                                        </tbody>:<tbody><tr><td colSpan={props.thead.length} className="text-center">No hay registros</td></tr></tbody>}
              </Table>
            </div>
            <div className="p-2">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {paginas.map((row,key)=>{
                    return <li onClick={()=>{setPagina(key)}} key={key} className={(key)===pagina?"page-item active cursor-pointer":"page-item cursor-pointer"}><a className={(key)===pagina?"page-link text-white":"page-link"}>{key+1}</a></li>
                  })}
                </ul>
              </nav>
            </div>
          </>
}

export default App
