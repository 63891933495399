import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Config from '../helpers/Config'
import logo from '../assets/images/logo-blanco-pequeno.png'
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);


const App=(props)=>{
  let url = props.user.perfil!=='11'?"listado_grupos_admin":"listado_grupos";
  return  <Navbar collapseOnSelect expand="lg" bg="green" variant="dark">
            <Navbar.Brand href={Config.ConfigAppUrl+"sadminc/escuela/"+url+"?token="+queryStringParams.token} className="p-0">
              <img height="40" src={logo} alt={Config.Title}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {props.user.perfil!=='11'?<NavDropdown title="Usuarios" id="collasible-nav-dropdown">
                  <NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/escuela/listado_usuarios?filter=11&token="+queryStringParams.token}>Profesores</NavDropdown.Item>
                  <NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/escuela/listado_alumnos?token="+queryStringParams.token}>Alumnos</NavDropdown.Item>
                  <NavDropdown.Item className="d-none" href={Config.ConfigAppUrl+"sadminc/escuela/listado_usuarios?filter=0&token="+queryStringParams.token}>Administradores</NavDropdown.Item>
                </NavDropdown>:false}
                {props.user.perfil!=='11'?<Nav.Link href={Config.ConfigAppUrl+"sadminc/escuela/listado_grupos_admin?token="+queryStringParams.token}>Grupos de entrenamiento</Nav.Link>:false}
                <Nav.Link href={Config.ConfigAppUrl+"sadminc/escuela/listado_grupos?token="+queryStringParams.token}>Asistencia</Nav.Link>
                <Nav.Link href={Config.ConfigAppUrl+"sadminc/escuela/listado_grupos_documentos?token="+queryStringParams.token}>Documentos Alumnos y pagos</Nav.Link>
                {props.user.perfil==='11'?<Nav.Link href={Config.ConfigAppUrl+"sadminc/encuestas?token="+queryStringParams.token}>Evaluaciones</Nav.Link>:false}
                <NavDropdown title="Gestión" id="collasible-nav-dropdown2">
                  {props.user.perfil!=='11'?<NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/gestion/listado_tarifas?token="+queryStringParams.token}>Tarifas pagos profesores</NavDropdown.Item>:false}
                  {props.user.perfil!=='11'?<NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/gestion/listado_profesores_reemplazo?token="+queryStringParams.token}>Reemplazo clases</NavDropdown.Item>:false}
                  <NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/gestion/fotos_alumnos?token="+queryStringParams.token}>Documentos y fotos alumnos</NavDropdown.Item>
                  <NavDropdown.Item href={Config.ConfigAppUrl+"sadminc/estadisticas/partidos?token="+queryStringParams.token}>Gestión de estadísticas de partidos</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <Nav.Link href={Config.ConfigAppUrl+"sadminc/seguridad/cambiarClave?token="+queryStringParams.token}>
                  Cambiar Contraseña
                </Nav.Link>
                <Nav.Link eventKey={2} href={Config.ConfigAppUrl}>
                  Cerrar sesión
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
}
export default App
