import React, { useState, useEffect } from 'react';
import Functions from '../../helpers/Functions';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import escuelaLogo from '../../assets/images/escuela-logo.jpg';
import queryString from 'query-string';

const queryStringParams = queryString.parse(window.location.search);

const App = () => {
  const [data, setData] = useState(false);
  const [capturer, setCapturer] = useState(false);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    getInit();
  }, []);

  const getInit = () => {
    let data = {
      id: queryStringParams.id,
      skip: true,
      token: queryStringParams.token,
    };
    Functions.PostAsync("Puente", "encuesta", data, {}, { name: "callbackInit", funct: callbackInit });
  };

  const callbackInit = (dataR) => {
    if (dataR.response.data) {
      setData(dataR.response);
    }
  };

  const capture = () => {
    let screenshotTarget = document.getElementById("captureMe");
    html2canvas(screenshotTarget).then((canvas) => {
      const base64image = canvas.toDataURL();
      setCapturer(base64image);
      sendResultados(base64image);
    });
  };

  const descargar = () => {
    html2canvas(document.getElementById("captureMe")).then((canvas) => {
      const dataURL = canvas.toDataURL();
      downloadjs(dataURL, queryStringParams.token + '.png', 'image/png');
    });
  };

  const onChange = (e) => {
    let inputs_ = { ...inputs };
    inputs_[e.target.name] = e.target.value;
    setInputs(inputs_);
  };

  const sendResultados = (captura) => {
    let correo_electronico = document.getElementById("correo_electronico");
    let data = {
      id: queryStringParams.id,
      token: queryStringParams.token,
      capture: captura,
      lista_correos: inputs.lista_correos,
      correo_electronico: correo_electronico.value,
      encuestador_token: queryStringParams.encuestador_token,
    };
    Functions.PostAsync("Puente", "sendResultados", data, {}, { name: "callbackSendResultados", funct: callbackSendResultados });
  };

  const callbackSendResultados = (dataR) => {
    alert("Los datos han sido enviados al correo inscrito");
  };

  return (
    <>
      <div className="container">
        <div className="row mt-5 mb-5 border-bottom pb-4">
          <div className="col text-center">
            <div className="btn btn-primary" onClick={capture}>Compartir resultados</div>
            <div className="btn btn-primary" onClick={descargar}>Descargar resultados</div>
            <input
              type="hidden"
              id="correo_electronico"
              className="form-control"
              placeholder="Correo electrónico del jugador"
              defaultValue={(data && data.encuestado !== undefined) ? data.encuestado.correo_electronico : false}
              disabled={(data && data.encuestado !== undefined) ? data.encuestado.correo_electronico : false}
            />
          </div>
        </div>
      </div>
      <div className="container" id="captureMe">
        <div className="row">
          {data ? (
            <>
              <div className="col-12">
                <div className="text-center">
                  <img src={escuelaLogo} alt="pgrw" className="img-fluid" />
                </div>
                <div className="bg-dark text-white p-2 text-uppercase h4 text-center">
                  Nivel {data.encuesta.label}
                </div>
                <div className="row h6">
                  <div className="col-4"><b>Fecha:</b></div>
                  <div className="col">{data.encuestado.fecha}</div>
                </div>
                <div className="row h6">
                  <div className="col-4"><b>Nombres y apellidos:</b></div>
                  <div className="col">{data.encuestado.nombres}</div>
                </div>
                <div className="row h6">
                  <div className="col-4"><b>Profesor:</b></div>
                  <div className="col">{data.encuestado.encuestador_nombre}</div>
                </div>
                <div className="row h6">
                  <div className="col-4"><b>Trimestre:</b></div>
                  <div className="col"><input type="text" name="trimestre" placeholder="1, 2 o 3" style={{ border: "none" }} value={data.encuestado.trimestre}></input></div>
                </div>
              </div>
              <div className="row mt-5">
                {Object.entries(data.data).length > 0 && Object.entries(data.data).map((row2, key2) => {
                  let row_ = row2[1];
                  return (
                    <div className="col-12" key={key2}>
                      <div className="card mb-3">
                        <div className="card-header text-white" style={{ backgroundColor: "#007f4c" }}>
                          {row_.label}
                        </div>
                        <div className="card-body">
                          {Array.isArray(data.respuestas_encuesta[row2[0]]) && data.respuestas_encuesta[row2[0]].map((row, key) => (
                            <div className="col-12 mb-3 pb-2 border-bottom" key={key}>
                              <div className="row">
                                <div className="col-12 col-sm-10 border-right">
                                  {typeof row.label === 'object' ? JSON.stringify(row.label) : row.label}
                                </div>
                                <div className="col-12 col-sm-2">
                                  {typeof row.respondio === 'object' ? JSON.stringify(row.respondio) : row.respondio}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-12 col-sm-12 pt-5 text-center">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-header">
                        Análisis y recomendaciones
                      </div>
                      <div className="card-body">{data.encuestado.analisis_recomendaciones}</div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 text-center">
                  <div className="col">
                    <b>ESCUELA LIGA ANTIOQUEÑA DE FÚTBOL</b>
                  </div>
                </div>
                <div className="row mt-1 text-center">
                  <div className="col">
                    Calle 49B #7431 PBX: 2601715 Ext. 113-114 elaf@fedefutbolant.com - www.laf.com.co Medellín – Colombia
                  </div>
                </div>
              </div>
            </>
          ) : false}
        </div>
      </div>
    </>
  );
};

export default App;
