import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Select from '../../screens/select';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=(props)=>{
  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
        data.filter   =   11
    Functions.PostAsync("Puente","profesoresReemplazo",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
    Functions.PostAsync("Puente","asignarTarifas",data,{},{name:"callbackContinue",funct:callbackContinue})
  }
  const callbackContinue=(data)=>{
    setMessage(true)
    setTimeout(function(){ setMessage(false) }, 4000);
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  return  <div className="container">
            <div className="row">
              <div className="col mt-3">
                <form onSubmit={onSubmit}>
                  <div className="card">
                    <div className="card-header">
                      Módulo de suplencia de profesores por clases
                    </div>
                    <div className="card-content">
                      <div className="card-body">
                        {message?<div className="row">
                          <div className="col-12 " >
                            <div className="alert alert-success" role="alert">
                              Los datos han sido guardados correctamente
                            </div>
                          </div>
                        </div>:false}
                        <div className="row">
                          <div className="col-12 col-sm-3 mb-2" >
                            <Select
                              required={true}
                              data={data}
                              name="principal"
                              selectDefault="Seleccione el principal"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-12 col-sm-3 mb-2" >
                            <Select
                              required={true}
                              data={data}
                              name="suplente"
                              selectDefault="Seleccione el reemplazo"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-12 col-sm-3 mb-2" >
                            <input type="date" name="fecha" className="form-control" placeholder="fecha de suplencia" onChange={onChange}/>
                          </div>
                          <div className="col-12 col-sm-3 mb-2">
                            <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
}

export default App
