import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginador';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let id=0
let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]
let tipo_respuestas=[
  {
    label:"Una respuesta",
    value:1,
  },
  {
    label:'Una respuesta con campo "Otro"',
    value:2,
  },
  {
    label:'Respuesta múltiple',
    value:3,
  },
  {
    label:'Respuesta múltiple con campo "Otro"',
    value:4,
  },
  {
    label:'Respuesta abierta (una sola linea)',
    value:5,
  },
  {
    label:'Respuesta abierta (varias lineas)',
    value:6,
  },
]
const App=()=>{
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [categorias_contenidos, setCategorias_contenidos] = useState([]);


  const getInit=()=>{
    let data                =   {token:queryStringParams.token}
        data.encuesta_token =   queryStringParams.id
    Functions.PostAsync("Puente","preguntas",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
    if (data.response.categorias_contenidos) {
      setCategorias_contenidos(data.response.categorias_contenidos)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  useEffect(() => {
    if (!open) {
      getInit()
    }
    if (open.encuestas_preguntas_id!==undefined) {
      id  =   open.encuestas_preguntas_id
    }else {
      id  =   0
    }
  },[open])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.encuestas_preguntas_id =   id
        data.encuesta_token         =   queryStringParams.id
    Functions.PostAsync("Puente","setPreguntas",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setOpen(false)
    getInit()
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Título",
        value:"label",
      },
      {
        label:"Editar",
        value:"editar",
      },
  ]

  const Form=(props)=>{
    return  <form onSubmit={onSubmit}>
              <div className="card">
                <div className="card-header">
                  Crear Evaluación
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3 border-right text-right">
                      Pregunta
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <Input
                              defaultValue={open.label}
                              autoComplete="off"
                              required={true}
                              title="¿Cuál es la pregunta?"
                              type="text"
                              name="label"
                              className="form-control"
                              onChange={props.onChange}
                          />
                        </div>
                        <div className="col">
                          <Select
                            defaultValue={open.encuesta_categorias_contenidos_id}
                            required={true}
                            data={categorias_contenidos}
                            name="encuesta_categorias_contenidos_id"
                            title="Categorias de contenido"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Select
                            defaultValue={open.campo_obligatorio}
                            required={true}
                            data={si_no}
                            name="campo_obligatorio"
                            title="¿Es una respuesta obligatoria?"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                        <div className="col">
                          <Select
                            defaultValue={open.tipo_respuestas}
                            required={true}
                            data={tipo_respuestas}
                            name="tipo_respuestas"
                            title="Tipo de Respuesta"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <Textarea
                          defaultValue={open.descripcion}
                          autoComplete="off"
                          title="Por favor ingresar las respuestas en el siguiente cuadro de texto separadas por punto y coma(;)"
                          type="text"
                          name="descripcion"
                          className="form-control"
                          onChange={props.onChange}
                      />
                      <div className="row">
                        <div className="col">
                          <button className="btn btn-primary" type="submit">Guardar</button>
                          <div className="btn btn-secondary ml-2" onClick={()=>{setOpen(false)}}>Cerrar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
  }

  return  <div className="container-fluid">
            <div className="row pt-2">
              <div className="col text-right">
                {!open?<div className="btn btn-primary" onClick={()=>{setOpen(true)}}>Agregar</div>:<div className="btn btn-primary" onClick={()=>{setOpen(false)}}>Cerrar</div>}
                <a className="btn btn-secondary ml-2" href={Config.ConfigAppUrl+"sadminc/encuestas?token="+queryStringParams.token}>Volver a la lista de evaluaciones</a>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                {!open?<Table
                  thead={thead}
                  data={data}
                  setOpen={setOpen}
                />:<Form  inputs={inputs}
                          onChange={onChange} />}
              </div>
            </div>
          </div>
}
export default App
