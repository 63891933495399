const App=()=>{
  return  <div className="container">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    Crear preguntas a encuesta
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3 text-right">
                        Parámetros
                      </div>
                      <div className="col">
                        Parámetros
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
}

export default App
