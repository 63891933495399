import { BrowserRouter, Route, Switch  } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import ListadoPartidos from './ListadoPartidos';
import Add from './Agregar';


const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/partidos/gestion"} render={()=><ListadoPartidos/>}/>
              <Route exact path={"/sadminc/partidos/add"} render={()=><Add/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
