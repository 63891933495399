import React,{useState,useEffect} from 'react';
import Input from '../../screens/inputs';
import Functions from '../../helpers/Functions';
import Select from '../../screens/select';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);
let inputs  = {}
const App=()=>{

  const [data, setData] = useState(false);
  const [data2, setData2] = useState([]);

  const onChange=(e)=>{
      inputs[e.target.name] = e.target.value
  }

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
        if (parseInt(queryStringParams.filter)>0) {
          data.filter = parseInt(queryStringParams.filter)
        }
    Functions.PostAsync("Puente","profesores",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
      setData2(data.response.grupos)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  return  <div className="container mt-3">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col border-right">
                        <b>Equipo anfitrión</b>
                        <div className="mt-2">
                          <Select
                            defaultValue={""}
                            required={true}
                            data={data2}
                            name="anfitrion"
                            selectDefault="Anfitrión"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <b>Equipo visitante</b>
                        <div className="mt-2">
                          <Select
                            defaultValue={""}
                            required={true}
                            data={data2}
                            name="visitante"
                            selectDefault="Visitante"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col border-right">
                        11
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
}

export default App
