import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginadorEncuestas';
import Graficos from '../../screens/Graficos/Lineas';
import  html2canvas from 'html2canvas';
import  queryString  from 'query-string';
const   queryStringParams = queryString.parse(window.location.search);
const   screenshotTarget = document.body;

const Resultados=(props)=>{
  return <>
            {props.resultados?<><div className="row justify-content-center text-left">
              <div className="card">
                <div className="card-header h4">
                  Resultados generales
                </div>
                <div className="card-body">
                  <div className="row justify-content-center">
                    {Object.entries(props.resultados.preguntas_string).map((row,key)=>{
                      return  <div key={key} className="col-12 col-sm-6 border-bottom mb-3">
                                {row[1]}
                                <div className="mb-2 ml-4 p-1">
                                  {props.resultados.preguntas_contador!==undefined && props.resultados.preguntas_contador[row[0]]!==undefined?<>
                                    <Graficos data={Object.entries(props.resultados.preguntas_contador[row[0]])}/>compart
                                  </>:false}

                                </div>
                              </div>
                    })}
                  </div>
                </div>
              </div>
            </div></>:false}
          </>
}

const Despedida=(props)=>{
  return <><div className="row justify-content-center mb-3">
            <div className="col-12 col-sm-12 ">
              <div className="card text-center">
                <div className="card-header bg-primary text-white">Felicidades</div>
                <div className="card-body">
                  <div>
                    {props.encuesta.despedida}
                  </div>
                  <div>
                  </div>
                  <div onClick={props.sendRecomendaciones} className="btn btn-primary mt-3" href={Config.ConfigAppUrl + "encuestasResultado?encuestador_token="+queryStringParams.encuestador_token+"&id="+queryStringParams.id+"&token="+props.token}>
                    Terminar y compartir los resultados
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
}

let documento_=0

const App=()=>{
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDespedida,   setOpenDespedida] = useState(false);
  const [dataResultados,  setDataResultados] = useState(false);
  const [inputs, setInputs] = useState({});
  const [encuesta, setEncuesta] = useState({});
  const [token, setToken] = useState(false);

  const getInit=()=>{
    let data        =   {
                          token:queryStringParams.encuestador_token
                        }
    Functions.PostAsync("Puente","gruposProfesoresDocumentosAlumnos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(dataR)=>{
    if (dataR.response.data) {
      setData(dataR.response.data)
      
      let data        =   {
                            id:queryStringParams.id,
                            skip:true,
                            token:queryStringParams.encuestador_token,
                          }
      Functions.PostAsync("Puente","encuesta",data,{},{name:"callbackEnc",funct:callbackEnc})
    }
  }

  const callbackEnc=(dataR)=>{
    if (dataR.response.data) {
      setData2(dataR.response.data);
    }
    if (dataR.response.data.ver_resultados_encuesta==="Si" && dataR.response.resultados!==undefined) {
      setDataResultados(dataR.response.resultados)
    }
    if (dataR.response.encuesta) {
      setEncuesta(dataR.response.encuesta)
    }
    if (dataR.response.encuestado!==false) {
      document.location.href  =   Config.ConfigAppUrl + "encuestasResultado?id="+queryStringParams.id+"&token="+dataR.response.encuestado.token
    }
  }

  const onChange=(e)=>{
    let inputs_ =   {...inputs};
      inputs_[e.target.name]  = e.target.value
      setInputs(inputs_)
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.id])


  const onSubmit=(e)=>{
    e.preventDefault()
    let data = {...inputs}
        data.token                =   queryStringParams.token
        data.encuesta_id          =   queryStringParams.id
        data.encuestador_token    =   queryStringParams.encuestador_token
        data.documento            =   open.nro_documento
        data.nombres             =   open.nombres
        data.correo_electronico  =   open.correo_electronico
        Functions.PostAsync("Puente","setRespuestas",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(dataR)=>{
    setToken(dataR.response.token)
    let href  = Config.ConfigAppUrl + "encuestasResultado?encuestador_token="+queryStringParams.encuestador_token+"&id="+queryStringParams.id+"&token="+dataR.response.token
    document.location.href  =   href
  }

  const capture=()=>{

    html2canvas(screenshotTarget).then((canvas) => {
      const base64image = canvas.toDataURL("image/png");
      let inputs_ =   {...inputs};
          inputs_.capture     = base64image
          setInputs(inputs_)
    });

  }

  const sendRecomendaciones=()=>{
    let data = {...inputs}
        data.token                =   token
        data.encuesta_id          =   queryStringParams.id
        data.encuestador_token    =   queryStringParams.encuestador_token
        Functions.PostAsync("Puente","sendRecomendaciones",data,{},{name:"callbackSendRecomendaciones",funct:callbackSendRecomendaciones})
  }

  const callbackSendRecomendaciones=(data)=>{
    let href  = Config.ConfigAppUrl + "encuestasResultado?encuestador_token="+queryStringParams.encuestador_token+"&id="+queryStringParams.id+"&token="+token
    document.location.href  =   href
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Nombres",
        value:"nombres",
      },
      {
        label:"Fecha nacimiento",
        value:"fecha_nacimiento",
      },
      {
        label:"Lugar nacimiento",
        value:"lugar_nacimiento",
      },
      {
        label:"Documento",
        value:"nro_documento",
      },
      {
        label:"Correo",
        value:"correo_electronico",
      },
      {
        label:"Evaluar",
        value:"evaluar",
      },
  ]

  return  <div className="container mt-2">
            {openDespedida?<Despedida sendRecomendaciones={sendRecomendaciones} onChange={onChange} token={token} encuesta={encuesta} data={data} resultados={dataResultados}/>:false}
            {dataResultados && openDespedida?<Resultados
                                                          capture={capture}
                                                          inputs={inputs}
                                                          onChange={onChange}
                                                          encuesta={encuesta}
                                                          data={data}
                                                          resultados={dataResultados}
                                              />:false}
            <div className={!openDespedida?"col-12 mb-2":"d-none"}>
              <div className={!open?"":"d-none"}>
                  <div className={open?"d-none":"row mb-2 mt-4"}>
                    <div className="col-12 mb-2">
                      <Table
                        thead={thead}
                        data={data}
                        token={queryStringParams.token}
                        setOpen={setOpen}
                      />
                    </div>
                  </div>
              </div>
            </div>
            <div className={!openDespedida?"":"d-none"}>
              <form onSubmit={onSubmit}>
                <div className={open?"col":"d-none"}>
                  {
                    Object.entries(data2).length>0?<>
                      {Object.entries(data2).map((row2,key2)=>{
                        let row_  = row2[1]
                        return  <div key={key2} className="card mb-3">
                                  <div className="card-header bg-primary2 text-white">
                                    {row_.label}
                                  </div>
                                  <div className="card-body">
                                    {
                                      Array.isArray(row_.data)?<>
                                      {row_.data.map((row,key)=>{
                                        return  <div className="col-12 mb-2" key={key}>
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-12 col-sm-8 border-right">
                                                          {row.label}
                                                        </div>
                                                        <div className="col">
                                                          {row.opciones.map((row2,key2)=>{
                                                            if (row.tipo_respuestas==="1") {
                                                              return  <div key={key2}>
                                                                        <input  type="radio"
                                                                            required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                            className="mr-2"
                                                                            onChange={onChange}
                                                                            name={'respuestas['+row.token+']'}
                                                                            defaultValue={row2} />
                                                                        {row2}
                                                                      </div>
                                                            }else if (row.tipo_respuestas==="2") {
                                                              return  <div key={key2}>
                                                                        <input  type="radio"
                                                                                onChange={onChange}
                                                                                required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                                className="mr-2"
                                                                                name={'respuestas['+row.token+']'}
                                                                                defaultValue={row2} />
                                                                        {row2}
                                                                      </div>
                                                            }else if (row.tipo_respuestas==="3") {
                                                              return  <div key={key2}>
                                                                        <input  type="checkbox"
                                                                                onChange={onChange}
                                                                                required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                                className="mr-2"
                                                                                name={'respuestas['+row.token+']['+row2+']'}
                                                                                defaultValue={row2} />
                                                                        {row2}
                                                                      </div>
                                                            }else if (row.tipo_respuestas==="4") {
                                                              return  <div key={key2}>
                                                                        <input  type="checkbox"
                                                                                onChange={onChange}
                                                                                required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                                className="mr-2"
                                                                                name={'respuestas['+row.token+']['+row2+']'}
                                                                                defaultValue={row2} />
                                                                        {row2}
                                                                      </div>
                                                            }else if (row.tipo_respuestas==="5") {
                                                              return  <div key={key2}>
                                                                        <input  type="text"
                                                                                onChange={onChange}
                                                                                placeholder="Respuesta"
                                                                                required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                                className="form-control mr-2"
                                                                                name={'respuestas['+row.token+']'}
                                                                                defaultValue={row2} />
                                                                        {row2}
                                                                      </div>
                                                            }else if (row.tipo_respuestas==="6") {
                                                              return  <div key={key2}>
                                                                        <textarea
                                                                                onChange={onChange}
                                                                                placeholder="Respuesta"
                                                                                required={row.campo_obligatorio!==undefined && row.campo_obligatorio==='Si' && 1===2?true:false}
                                                                                className="form-control mr-2"
                                                                                name={'respuestas['+row.token+']'}
                                                                        >
                                                                        </textarea>
                                                                        {row2}
                                                                      </div>
                                                            }else {
                                                              return false
                                                            }
                                                          })}

                                                          {
                                                            row.tipo_respuestas==="2"?<div>
                                                                      <div>Otra opción</div>
                                                                      <input  type="text"
                                                                              onChange={onChange}
                                                                              className="mr-2"
                                                                              name={'respuestas['+row.token+']'}
                                                                              placeholder="Otra opción"
                                                                              />

                                                                    </div>:<></>
                                                            }

                                                            {
                                                              row.tipo_respuestas==="4"?<div>
                                                                        <div>Otra opción</div>
                                                                        <input  type="text"
                                                                                onChange={onChange}
                                                                                className="mr-2"
                                                                                name={'respuestas['+row.token+'][otro]'}
                                                                                placeholder="Otra opción"/>
                                                                      </div>:<></>
                                                              }

                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                        })
                                      }
                                      </>:false
                                    }
                                  </div>
                                </div>
                      })}
                    </>:false
                  }
                  <div className="card mb-3">
                    <div className="card-body pb-0">
                      <select id="trimestre"
                              onChange={onChange}
                              name="trimestre"
                              className="form-control"
                              required
                      >
                        <option value="">Trimestre</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                    </div>
                    <div className="card-body">
                      <textarea name="analisis_recomendaciones" className="form-control" onChange={onChange} placeholder="Análisis y recomendaciones..." required></textarea>
                    </div>
                  </div>
                  <div className="col pb-5 mb-5">
                    <button className="btn btn-primary">Guardar y procesar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
}

export default App
