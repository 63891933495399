import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Store from '../../helpers/Store';
import logo from '../../assets/images/logo-negro-pequeno.png'
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=()=>{
  const height  = window.innerHeight;

  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(false);

  const onSubmit=(e)=>{
    setError(false)
    e.preventDefault()
    let data     =   {...inputs}
        data.skip=true
    Functions.PostAsync("Puente","login",data,{},{name:"callbackLogin",funct:callbackLogin})
  }

  const callbackLogin=(data)=>{
    if (data.response.error) {
      setError(data.response.error)
    }else {
      Store.set("user",data.response.data)
      if (data.response.data.perfil==='11') {
        document.location.href  = Config.ConfigAppUrl+"sadminc/escuela/listado_grupos?token="+data.response.data.token
      }else {
        document.location.href  = Config.ConfigAppUrl+"sadminc/escuela/listado_grupos_admin?token="+data.response.data.token
      }

    }
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }
  return  <div className="container-fluid bg-green" style={{minHeight:height+"px"}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 mt-5">
                  <form onSubmit={onSubmit}>
                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="logo">
                          <img width="25%" src="https://www.laf.com.co/fotos/Image/header-logo.png" alt="pgrw" className="bg-green  rounded-circle mx-auto d-block"/>
                        </div>
                        <div className="mb-3">
                          <img src={logo} alt="pgrw" className="col-4  mx-auto d-block"/>
                        </div>
                        <div className="mb-3">
                          <input required={true} placeholder="Correo electrónico" type="text" name="login" onChange={onChange} className="form-control" />
                        </div>
                        <div className="mb-3">
                          <input required={true} placeholder="Clave" type="password" name="password" onChange={onChange} className="form-control" />
                        </div>
                        {error?<div className="mb-3 bg-danger text-white text-center p-2">
                            {error}
                        </div>:false}
                        <div className="mb-3">
                          <button className="btn btn-primary">Ingresar</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
}
export default App
