import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Input from '../../screens/inputs';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=(props)=>{
  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState(false);

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
    Functions.PostAsync("Puente","GetTarifas",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setInputs(data.response.data)
    }
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
    Functions.PostAsync("Puente","asignarTarifas",data,{},{name:"callbackContinue",funct:callbackContinue})
  }
  const callbackContinue=(data)=>{
    setMessage(true)
    setTimeout(function(){ setMessage(false) }, 4000);
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  return  <div className="container">
            <div className="row">
              <div className="col mt-3">
                <form onSubmit={onSubmit}>
                  <div className="card">
                    <div className="card-header">
                      Tarifas de pagos a profesores vigentes
                    </div>
                    <div className="card-content">
                      <div className="card-body">
                        {message?<div className="row">
                          <div className="col-12 " >
                            <div className="alert alert-success" role="alert">
                              Los datos han sido guardados correctamente
                            </div>
                          </div>
                        </div>:false}
                        <div className="row">
                          <div className="col-12 col-sm-4 mb-2" >
                            <Input
                                className="form-control"
                                defaultValue={inputs.tarifa_clase}
                                required={true}
                                data={inputs}
                                name="tarifa_clase"
                                title="Tarifa por clase"
                                onChange={onChange}
                              />
                          </div>
                          <div className="col-12 col-sm-4 mb-2">
                            <Input
                                className="form-control"
                                defaultValue={inputs.tarifa_partido}
                                required={true}
                                data={inputs}
                                name="tarifa_partido"
                                title="Tarifa por partido"
                                onChange={onChange}
                              />
                          </div>
                          <div className="col-12 col-sm-4 pt-4 mb-2">
                            <button type="submit" className="btn btn-primary btn-block">Actualizar</button>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
}

export default App
