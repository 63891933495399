import { BrowserRouter, Route, Switch  } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import CambiarClave from './CambiarClave';

const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/seguridad/cambiarClave"} render={()=><CambiarClave/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
