import { BrowserRouter, Route, Switch  } from "react-router-dom";
//import Config from "../../helpers/Config";
import StateContext from '../../helpers/ContextState';
import ListadoEncuestas from './ListadoEncuestas';
import EncuestasPreguntas from './EncuestasPreguntas';
import ListadoPreguntas from './ListadoPreguntas';


const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/encuestas"} render={()=><ListadoEncuestas/>}/>
              <Route exact path={"/sadminc/encuestas-preguntas"} render={()=><EncuestasPreguntas/>}/>
              <Route exact path={"/sadminc/encuestas-lista-preguntas"} render={()=><ListadoPreguntas/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
