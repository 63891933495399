import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const colores=[
  "#8884d8",
  "#7754d8",
  "#6694d8",
  "#55C4d8",
  "#44a4d8",
  "#3384d8",
  "#2284d8",
  "#1184d8",
]

// const data2 = [
//   {
//     name: 'Jun', mujeres: 3490, hombres: 4300, amt: 2100,
//   },
//   {
//     name: 'Jul', mujeres: 4000, hombres: 2400, amt: 2400,
//   },
//   {
//     name: 'Ago', mujeres: 3000, hombres: 1398, amt: 2210,
//   },
//   {
//     name: 'Sep', mujeres: 2000, hombres: 9800, amt: 2290,
//   },
//   {
//     name: 'Oct', mujeres: 2780, hombres: 3908, amt: 2000,
//   },
//   {
//     name: 'Nov', mujeres: 1890, hombres: 4800, amt: 2181,
//   },
//   {
//     name: 'Dic', mujeres: 2390, hombres: 3800, amt: 2500,
//   },
// ];

const App =(props)=>{
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let data_ = []
    props.data.map((row,key)=>{
      return data_.push({[row[0]]: row[1]})
    })
    setTimeout(function(){ setData(data_); setOpen(true); }, 2000);
  },[props.data])

  return  <>
            {open>0? <>
                              <BarChart
                                width={400}
                                height={250}
                                data={data}
                                margin={{
                                  top: 5, right: 30, left: 20, bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                {props.data.map((row,key)=>{
                                  return <Bar key={key} dataKey={row[0]} fill={colores[key]} />
                                })}
                              </BarChart>
                            </>:false}
          </>
}

export default App
