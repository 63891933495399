import { BrowserRouter, Route, Switch  } from "react-router-dom";
//import Config from "../../helpers/Config";
import StateContext from '../../helpers/ContextState';
import ListadoGrupos from './ListadoGrupos';
import ListadoPartidos from './ListadoPartidos';
import AgregarPartido from './AgregarPartido';


const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/estadisticas/partidos"} render={()=><ListadoGrupos/>}/>
              <Route exact path={"/sadminc/estadisticas/listadopartidos"} render={()=><ListadoPartidos/>}/>
              <Route exact path={"/sadminc/estadisticas/agregarPartidos"} render={()=><AgregarPartido/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
