import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginadorProfesores';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ListadoGruposEntrenamientos from '../escuela/ListadoGruposEntrenamientos';
const  queryStringParams = queryString.parse(window.location.search);

const ModalAddGroup = (props) => {
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [q, setQ] = useState(false);
  const [addGroup, setGroup] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChangeQ=(e)=>{
    if (e.target.value!=='') {
        setQ(e.target.value)
    }else {
        setQ(false)
    }
    getInit()
  }

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
        if (parseInt(queryStringParams.filter)>0) {
            data.filter = parseInt(queryStringParams.filter)
        }
        if (q) {
            data.q  = q
        }
    Functions.PostAsync("Puente","gruposEntrenamientos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
        setData(data.response.data)
        setData2(data.response.grupos)
    }
  }

  useEffect(() => {
    getInit()
    },[queryStringParams.token])
    
    useEffect(() => {
    if (!open) {
        //getInit()
    }
    if (open.grupos_asignados!==undefined) {
        setLista(open.grupos_asignados)
    }
    if (open) {
        setInputs(open)
    }
    },[open])

    const onSubmit2=(e)=>{
      e.preventDefault()
      let data                      =   {...inputs}
          data.token                =   queryStringParams.token
          data.profesor_id          =   open.idusuario
      Functions.PostAsync("Puente","saveGruposEntrenamiento",data,{},{name:"callbackContinue2",funct:callbackContinue2})
    }
    
    const callbackContinue2=(data)=>{
    setOpen(false)
    setGroup(false)
    }

    const onSubmit=(e)=>{
      e.preventDefault()
      let data                      =   {...inputs}
          data.token                =   queryStringParams.token
          data.profesor_id          =   open.idusuario
      Functions.PostAsync("Puente","asignarProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
      }
      
      const callbackContinue=(data)=>{
      setLista(data.response.data)
      getInit()
      }
      
      const onChange=(e)=>{
      let inputs_ =   inputs;
          inputs_[e.target.name]=e.target.value
          setInputs(inputs_)
      }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Agregar Grupos
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar grupos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Container>
            <Row>
              <Col sm={8}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nombres</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre del grupo"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Año</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Año"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cantidad"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Grupo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Grupo"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Fecha de inicio"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Fecha finalización</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Fecha finalización"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Día 1 de la semana</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Día 1 de la semana"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Día 2 de la semana</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Día 2 de la semana"
                    autoFocus
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Día 3 de la semana</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Día 3 de la semana"
                    autoFocus
                  />
                </Form.Group>
              </Col>
            </Row>
            
            
            </Container>
          </form>
          
        <div className="card">
            <div className="card-header">
            </div>
            <div className="card-body">
                <form onSubmit={onSubmit2}>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Nombres
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.label}
                            required={true}
                            data={data2}
                            name="label"
                            placeholder="Nombre del grupo"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Año
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.ano}
                            required={true}
                            data={data2}
                            name="ano"
                            placeholder="Año"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Cantidad
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.cantidad}
                            required={true}
                            data={data2}
                            name="cantidad"
                            placeholder="Cantidad"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Grupo
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.grupo}
                            required={true}
                            data={data2}
                            name="grupo"
                            placeholder="Grupo"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Fecha de inicio
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.fecha_inicio}
                            required={true}
                            data={data2}
                            name="fecha_inicio"
                            type="date"
                            placeholder="Fecha inicio"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Fecha finalización
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Input
                            className="form-control"
                            defaultValue={open.fecha_final}
                            required={true}
                            data={data2}
                            name="fecha_final"
                            type="date"
                            placeholder="Fecha final"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Día 1 de la semana
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Select
                            defaultValue={open.dia1}
                            required={true}
                            data={Functions.dias_semana}
                            name="dia1"
                            selectDefault="Día 1"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Día 2 de la semana
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Select
                            defaultValue={open.dia2}
                            required={true}
                            data={Functions.dias_semana}
                            name="dia2"
                            selectDefault="Día 2"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Día 3 de la semana
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Select
                            defaultValue={open.dia3}
                            name="dia3"
                            data={Functions.dias_semana}
                            selectDefault="Día 3"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 border-right text-right">
                    Cancha
                    </div>
                    <div className="col">
                    <div className="row">
                        <div className="col">
                        <Select
                            defaultValue={open.cancha}
                            required={true}
                            data={Functions.canchas}
                            name="cancha"
                            selectDefault="Cancha"
                            onChange={props.onChange}
                        />
                        </div>
                    </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-3 border-right text-right">

                    </div>
                    <div className="col">
                    <button type="submit" className="btn btn-primary mr-2">
                        Guardar cambios
                    </button>
                    <div className="btn btn-secondary" onClick={()=>setOpen(false)}>
                        Cerrar
                    </div>
                    </div>
                </div>
                </form>
                {open.perfil==='11'?<>
                <form onSubmit={onSubmit}>
                    <div className="card mt-4">
                    <div className="card-header bg-green text-white">
                        Asignar grupos de usuarios
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className="col-3 border-right text-right">
                            Formulario
                            </div>
                            <div className="col">
                            <div className="row">
                                <div className="col">
                                <Select
                                    defaultValue={open.ver_resultados_encuesta}
                                    required={true}
                                    data={data2}
                                    name="grupo"
                                    selectDefault="Seleccione"
                                    onChange={props.onChange}
                                />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <button className="btn btn-primary">Asignar</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        {props.lista.length>0?<>
                            <div className="row">
                            <div className="col-12 mb-2">
                                Grupos asignados
                            </div>
                            <div className="col-12">
                                <table className="table">
                                <thead>
                                    <th>Grupo</th>
                                    <th width="50">Acción</th>
                                </thead>
                                <tbody>
                                    {lista.map((row,key)=>{
                                    return  <tr key={key}>
                                                <td>{row.grupo}</td>
                                                <td><FontAwesomeIcon className="cursor-pointer" icon={faTrash} /></td>
                                            </tr>
                                    })}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </>:false}
                    </div>
                    </div>
                </form>
                </>:false}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAddGroup;