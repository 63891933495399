import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let meses =   [
                {
                  label:"Febrero",
                  value:"02",
                },
                {
                  label:"Marzo",
                  value:"03",
                },
                {
                  label:"Abril",
                  value:"04",
                },
                {
                  label:"Mayo",
                  value:"05",
                },
                {
                  label:"Junio",
                  value:"06",
                },
                {
                  label:"Julio",
                  value:"07",
                },
                {
                  label:"Agosto",
                  value:"08",
                },
                {
                  label:"Septiembre",
                  value:"09",
                },
                {
                  label:"Octubre",
                  value:"10",
                },
                {
                  label:"Noviembre",
                  value:"11",
                },
                {
                  label:"Diciembre",
                  value:"12",
                },
              ]
const App=(props)=>{

  const [data, setData] = useState([]);
  const [data2, setData2] = useState({});
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});

  const getInit=(inputs_)=>{
    let data        =   {
                          token:queryStringParams.token,
                          grupo_entrenamiento_id:props.open.grupo_entrenamiento_id,
                          mes:inputs_.mes
                        }
    Functions.PostAsync("Puente","gruposPartidosAll",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
  }


  const getInit2=(partido_id)=>{
    let data        =   {
                          token:queryStringParams.token,
                          partido_id:partido_id,
                        }
    Functions.PostAsync("Puente","gruposPartidosAllResultados",data,{},{name:"callbackInit2",funct:callbackInit2})
  }

  const callbackInit2=(data)=>{
    if (data.response.data) {
      setData2(data.response.data)
    }
    setOpen(true)
  }


  useEffect(() => {
    //getInit()
  },[props.open])

  const onChange=(e)=>{
    let inputs_ =   {...inputs};
    if (e.target.name==='mes' && e.target.value!=='') {
      inputs_[e.target.name]=e.target.value
      getInit(inputs_)
      return setInputs(inputs_);
    }

    if (e.target.value!=='' && e.target.name!=='mes') {
      inputs_[e.target.name]=e.target.value
      getInit2(e.target.value)
      setOpen(false)
    }else {
      setOpen(false)
    }
    setInputs(inputs_);
  }

  const onChangeSubmit=(e,row)=>{
    let data        =   {...inputs}
        data.row    =   JSON.stringify(row)
        data.value  =   e.target.value
        data.name   =   e.target.name
        data.token  =   queryStringParams.token
        data.grupo_entrenamiento_id=props.open.grupo_entrenamiento_id
    Functions.PostAsync("Puente","SetPartidosResult",data,{},{name:"callbackInit",funct:callbackInit})
  }

  return <>
          <div className="container">
            <div className="row mb-3">
              <div className="col text-right">
                <select onChange={onChange} name="mes" className="form-control">
                  <option value="">Seleccione el mes</option>
                  {meses.map((row,key)=>{
                    return  <option value={row.value}>{row.label}</option>
                  })}
                </select>
              </div>
              <div className="col text-right">

                <select onChange={onChange} name="partido_id" className="form-control">
                  <option value="">Seleccione el partido</option>
                  {data.map((row,key)=>{
                    return  <option value={row.partido_id}>{row.fecha} ({row.contendor})</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            {open?<table className="table">
              <thead>
                <th>
                  #
                </th>
                <th>
                  Alumno
                </th>
                <th>
                  MJ
                </th>
                <th>
                  GOL
                </th>
                <th>
                  TA
                </th>
                <th>
                  TR
                </th>
              </thead>
              <tbody>
                {
                  props.open.alumnos!==undefined?<>
                    {props.open.alumnos.map((row,key)=>{
                      return  <tr key={key}>
                                <td>{key+1}</td>
                                <td>{console.log(data2,row)}{row.nombres} {row.apellidos}</td>
                                <td><input defaultValue={(row.inscrito_id!==undefined && data2[row.inscrito_id]!==undefined)?data2[row.inscrito_id].mj:""} maxlength="4" name="mj" placeholder="MJ" min="0" max="120" type="number" onChange={(e)=>onChangeSubmit(e,row,"mj")}/></td>
                                <td><input defaultValue={(row.inscrito_id!==undefined && data2[row.inscrito_id]!==undefined)?data2[row.inscrito_id].gol:""} maxlength="4" name="gol" placeholder="GOLES" min="0" max="20" type="number" onChange={(e)=>onChangeSubmit(e,row,"gol")}/></td>
                                <td><input defaultValue={(row.inscrito_id!==undefined && data2[row.inscrito_id]!==undefined)?data2[row.inscrito_id].ta:""} maxlength="4" name="ta" placeholder="TARJETAS AMARILLAS" min="0" max="3" type="number" onChange={(e)=>onChangeSubmit(e,row,"ta")}/></td>
                                <td><input defaultValue={(row.inscrito_id!==undefined && data2[row.inscrito_id]!==undefined)?data2[row.inscrito_id].tr:""} maxlength="4" name="tr" placeholder="TARJETA ROJA" min="0" max="1" type="number" onChange={(e)=>onChangeSubmit(e,row,"tr")}/></td>
                              </tr>
                    })}
                  </>:false
                }
              </tbody>
            </table>:false}

          </div>
      </>
}
export default App
