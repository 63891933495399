import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Input from '../../screens/inputs';
import { NavLink } from "react-router-dom";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=(props)=>{
  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState(false);

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
    Functions.PostAsync("Puente","GetPartidos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setInputs(data.response.data)
    }
  }

  // const onSubmit=(e)=>{
  //   e.preventDefault()
  //   let data                      =   {...inputs}
  //       data.token                =   queryStringParams.token
  //   Functions.PostAsync("Puente","asignarTarifas",data,{},{name:"callbackContinue",funct:callbackContinue})
  // }
  // const callbackContinue=(data)=>{
  //   setMessage(true)
  //   setTimeout(function(){ setMessage(false) }, 4000);
  // }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  return  <div className="container">
            <div className="row">
              <div className="col mt-3 text-right">
                <NavLink to={"/sadminc/partidos/add?token="+queryStringParams.token} className="btn btn-primary">Agregar partido</NavLink>
              </div>
            </div>
          </div>
}

export default App
