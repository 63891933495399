const exportar  = {
  Prefijo:"/pwa",
  ConfigAppUrl : (document.location.hostname==='localhost')?window.location.origin+'/': (process.env.NODE_ENV==='development')?'https://cms.laf.com.co/':'https://cms.laf.com.co/',
  ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.programandoweb.net:10081/':(process.env.NODE_ENV==='development')?'https://socket.programandoweb.net:10081/':'https://socket.programandoweb.net:10081/',
  ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
  ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/apiMiEscuela/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.laf.com.co/ApiRest/':'https://api.laf.com.co/ApiRest/',
  // ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/api.laf.com.co/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.laf.com.co/ApiRest/':'https://api.laf.com.co/ApiRest/',
  Title:'Liga Antioqueña de Fútbol',
  Alt:'Liga Antioqueña de Fútbol',
  db:"Liga Antioqueña de Fútbol",
}

export default exportar