import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginador';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import { faPlus,faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let id=0
let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]
const App=()=>{
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});

  const getInit=()=>{
    let data        =   {token:queryStringParams.token}
    Functions.PostAsync("Puente","encuestas",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  useEffect(() => {
    if (!open) {
      getInit()
    }
    if (open.encuesta_id!==undefined) {
      id  =   open.encuesta_id
    }else {
      id=0
    }
  },[open])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.encuesta_id          =   id
    Functions.PostAsync("Puente","setEncuesta",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setOpen(false)
    getInit()
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Título",
        value:"label",
      },
      {
        label:"Editar",
        value:"editar",
        extra:[{
                url:Config.ConfigAppUrl+"encuestas?encuestador_token="+queryStringParams.token+"&id=",
                label:<FontAwesomeIcon title="Abrir la evaluación" className="cursor-pointer" icon={faSearch}/>,
                target:"_blank"
              },{
                url:Config.ConfigAppUrl+"sadminc/encuestas-lista-preguntas?token="+queryStringParams.token+"&id=",
                label:<FontAwesomeIcon title="Agregar Preguntas" className="cursor-pointer" icon={faPlus}/>,
                target:"_self"
              },]
      },
  ]

  const Form=(props)=>{
    return  <form onSubmit={onSubmit}>
              <div className="card">
                <div className="card-header">
                  Crear Evaluación
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3 border-right text-right">
                      Formulario
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <Input
                              defaultValue={open.label}
                              autoComplete="off"
                              required={true}
                              title="Título"
                              type="text"
                              name="label"
                              className="form-control"
                              onChange={props.onChange}
                          />
                        </div>
                        <div className="col">
                          <Select
                            defaultValue={open.ver_resultados_encuesta}
                            required={true}
                            data={si_no}
                            name="ver_resultados_encuesta"
                            title="Permitir que el usuario vea los resultados"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Textarea
                              required={true}
                              defaultValue={open.introduccion}
                              autoComplete="off"
                              title="Introducción"
                              type="text"
                              name="introduccion"
                              className="form-control"
                              onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Textarea
                              required={true}
                              defaultValue={open.despedida}
                              autoComplete="off"
                              title="Mensaje de despedida"
                              type="text"
                              name="despedida"
                              className="form-control"
                              onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Select
                            defaultValue={open.publica}
                            required={true}
                            data={si_no}
                            name="publica"
                            title="Pública"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                        <div className="col">
                          <Select
                            defaultValue={open.anonima}
                            required={true}
                            data={si_no}
                            name="anonima"
                            title="Anónima"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                        <div className="col">
                          <Select
                            defaultValue={open.estado}
                            required={true}
                            data={si_no}
                            name="estado"
                            title="Activar"
                            selectDefault="Seleccione"
                            onChange={props.onChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button className="btn btn-primary" type="submit">Guardar</button>
                          <div className="btn btn-secondary ml-2" onClick={()=>{setOpen(false)}}>Cerrar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
  }

  return  <div className="container-fluid">
            <div className="row pt-2">
              <div className="col text-right">
                <div className="d-none">
                  Crear categoria contenidos
                </div>
                {!open?<div className="btn btn-primary" onClick={()=>{setOpen(true)}}>Agregar</div>:<div className="btn btn-primary" onClick={()=>{setOpen(false)}}>Cerrar</div>}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                {!open?<Table
                  thead={thead}
                  data={data}
                  setOpen={setOpen}
                />:<Form  inputs={inputs}
                          onChange={onChange} />}
              </div>
            </div>
          </div>
}
export default App
