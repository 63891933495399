import React from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../helpers/cropImage'

class App extends React.Component {
  state = {
    image:   this.props.src,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    croppedArea:false,
    croppedAreaPixels:false,
    rotation:0,
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedArea:croppedArea,croppedAreaPixels:croppedAreaPixels})
    //console.log(croppedArea, croppedAreaPixels)
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  cropper=()=>{
    async function croping(_this){
      let croppedImage = await getCroppedImg(
        _this.state.image,
        _this.state.croppedAreaPixels,
        _this.state.rotation
      )
      _this.props.setState({avatar:{ croppedImage },miImg: croppedImage })
      _this.props.context({dialog:{status:false}})
      _this.props.onSubmitUpload(croppedImage)
    }
    croping(this)
  }

  render() {
    return (
      <div className="crop">
        <div className="crop-container" style={{height:"360px"}}>
          <Cropper
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            style={{containerStyle:{height:"85%",width: "80%",margin: "0 auto"}}}
          />
        </div>
        <div className="btn btn-secondary btn-recortar"  onClick={this.cropper}>Recortar</div>
      </div>
    )
  }
}

export default App;
