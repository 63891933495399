import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginadorAlumnos';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let id=0
let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]
const App=(props)=>{
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [q, setQ] = useState(false);

  const onChangeQ=(e)=>{
    if (e.target.value!=='') {
      setQ(e.target.value)
    }else {
      setQ(false)
    }
    getInit()
  }

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
        if (parseInt(queryStringParams.filter)>0) {
          data.filter = parseInt(queryStringParams.filter)
        }
        if (q) {
          data.q  = q
        }
    Functions.PostAsync("Puente","alumnos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
      setData2(data.response.grupos)
    }
  }

  useEffect(() => {
    if (!open) {
      getInit()
    }
    if (open.grupos_asignados!==undefined) {
      setLista(open.grupos_asignados)
    }
    if (open) {
      setInputs(open)
    }
  },[open])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token

    Functions.PostAsync("Puente","guardarPerfilAlumno",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setOpen(false)
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Nombres",
        value:"nombres",
      },
      {
        label:"Apellidos",
        value:"apellidos",
      },
      {
        label:"Documento",
        value:"nro_documento",
      },
      {
        label:"Grupo",
        value:"grupo_entrenamiento",
      },
      {
        label:"F. Nacimiento",
        value:"fecha_nacimiento",
      },
      {
        label:"Asignar",
        value:"editar",
      },
  ]

  const deleteGrupo=(row)=>{
    let data       =   {}
        data.token =   queryStringParams.token
        data.id    =   row.id
    Functions.PostAsync("Puente","deleteAsignacionProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const Form=(props)=>{
    return  <form onSubmit={onSubmit}>
              <div className="card">
                <div className="card-header">
                  Gestionar perfil
                </div>
                <div className="card-body">

                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Grupo de entrenamiento
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Select
                              defaultValue={open.grupo_entrenamiento}
                              required={true}
                              data={data2}
                              name="grupo_entrenamiento"
                              selectDefault="Grupo de entrenamiento"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Nombres
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.nombres}
                              required={true}
                              data={data2}
                              name="nombres"
                              placeholder="Nombres"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Apellidos
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.apellidos}
                              required={true}
                              data={data2}
                              name="apellidos"
                              placeholder="Apellidos"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Documento
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.nro_documento}
                              required={true}
                              data={data2}
                              name="nro_documento"
                              placeholder="Documento"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Correo electrónico
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.correo_electronico}
                              required={true}
                              data={data2}
                              name="correo_electronico"
                              placeholder="Correo electrónico"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Teléfono
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.telefono}
                              required={true}
                              data={data2}
                              name="telefono"
                              placeholder="Teléfono"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Celular
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.celular}
                              required={true}
                              data={data2}
                              name="celular"
                              placeholder="Celular"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">

                      </div>
                      <div className="col">
                        <button type="submit" className="btn btn-primary">
                          Guardar
                        </button>
                      </div>
                    </div>
                    {props.lista.length>0?<>
                      <div className="row">
                        <div className="col-12 mb-2">
                          Grupos asignados
                        </div>
                        <div className="col-12">
                          <table className="table">
                            <thead>
                              <th>Grupo</th>
                              <th width="50">Acción</th>
                            </thead>
                            <tbody>
                              {lista.map((row,key)=>{
                                return  <tr key={key}>
                                          <td>{row.grupo}</td>
                                          <td><FontAwesomeIcon className="cursor-pointer" icon={faTrash} onClick={()=>deleteGrupo(row)}/></td>
                                        </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>:false}
                </div>
              </div>
            </form>
  }

  return  <div className="container-fluid">
            <div className="row pt-2">
              <div className="col">
                {!open?<Table
                  onChangeQ={onChangeQ}
                  thead={thead}
                  data={data}
                  token={queryStringParams.token}
                  setOpen={setOpen}
                />:<Form  inputs={inputs}
                          setData2={setData2}
                          open={open}
                          lista={lista}
                          data2={data2}
                          onChange={onChange} />}
              </div>
            </div>
          </div>
}
export default App
