import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginadorProfesores';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cropper from '../../screens/Cropper'
import Modal from '../../screens/modal';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);


let imagen={}
let id=0
let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]
const App=(props)=>{
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [q, setQ] = useState(false);
  const [state,setState] = useState({});



  const onChangeQ=(e)=>{
    if (e.target.value!=='') {
      setQ(e.target.value)
    }else {
      setQ(false)
    }
    getInit()
  }

  const getInit=()=>{
    // let data          =   {token:queryStringParams.token}
    //     if (parseInt(queryStringParams.filter)>0) {
    //       data.filter = parseInt(queryStringParams.filter)
    //     }
    //     if (q) {
    //       data.q  = q
    //     }
    // Functions.PostAsync("Puente","profesores",data,{},{name:"callbackInit",funct:callbackInit})
    let data        =   {
                          token:queryStringParams.token,
                        }
    Functions.PostAsync("Puente","gruposProfesoresDocumentosAlumnos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
      setData2(data.response.grupos)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  useEffect(() => {
    if (!open) {
      getInit()
    }
    if (open.grupos_asignados!==undefined) {
      setLista(open.grupos_asignados)
    }
    if (open) {
      setInputs(open)
    }
  },[open])


  const onSubmit2=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","saveUser",data,{},{name:"callbackContinue2",funct:callbackContinue2})
  }

  const callbackContinue2=(data)=>{
    setOpen(false)
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","asignarProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setLista(data.response.data)
    getInit()
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Nombres",
        value:"nombres",
      },
      {
        label:"Fecha nacimiento",
        value:"fecha_nacimiento",
      },
      {
        label:"Lugar nacimiento",
        value:"lugar_nacimiento",
      },
      {
        label:"Documento",
        value:"nro_documento",
      },
      {
        label:"Correo",
        value:"correo_electronico",
      },
      {
        label:"Asignar",
        value:"editar",
      },
  ]

  const deleteGrupo=(row)=>{
    let data       =   {}
        data.token =   queryStringParams.token
        data.id    =   row.id
    Functions.PostAsync("Puente","deleteAsignacionProfesor",data,{},{name:"callbackContinue3",funct:callbackContinue3})
  }

  const callbackContinue3=(data)=>{
    setLista(data.response.data)
  }

  const onSubmitUpload=(croppedImage)=>{
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
        data.imagen               =   croppedImage;
        data.imagen_parametros    =   JSON.stringify(imagen);
        data.open                 =   JSON.stringify(open)
        Functions.PostAsync("Puente","subirImagenAlumno",data,{},{name:"callbackContinue4",funct:callbackContinue4})
  }

  const callbackContinue4 = (data)=>{
    setOpen(false);
  }

  const handleCropperModal=(event)=>{
    let objeto  = this;
    let file      = event.target.files[0];
    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
   //do whatever operation you want to do here

   // if(Config.maxFileSize  < file_size){
   //  return this.setState({stop:true,error:"Tamaño supera lo permitido",file_type:file_type})
   // }

   imagen             =   {file_type:file_type,file_size:file_size}
   let reader         =   new FileReader();


    reader.onloadend  =   function(_this) {
      imagen           =  {
                            avatar:reader.result,
                            file:file,
                            file_size:file_size,
                            file_name:file_name,
                            file_type:file_type
                          }
      let modal = {
                show:true,
                size:"md",
                title:"",
                message:<Cropper src={reader.result} context={setModalShow} setState={setState} onSubmitUpload={onSubmitUpload}/>,
                btnText:"hide",
                ico:{
                      contentColor:"modal-ico-bg-green",
                      ico:'fas fa-check pl-1',
                    },
              }
      //console.log(imagen);
      setModalShow(modal)
    }

    reader.readAsDataURL(file);
  }

  const Form=(props)=>{
    return    <>
                <div className="card">
                  <div className="card-header">
                    Gestionar Usuarios
                  </div>
                  <div className="card-body">
                    <form onSubmit={onSubmit2}>
                      <div className="row">
                        <div className="col-3 border-right text-right">
                          Nombres
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <Input
                                className="form-control"
                                defaultValue={open.nombres}
                                required={true}
                                data={data2}
                                name="nombres"
                                placeholder="Nombres"
                                onChange={props.onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 border-right text-right">
                          Correo electrónico
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <Input
                                className="form-control"
                                defaultValue={open.correo_electronico}
                                required={true}
                                data={data2}
                                name="correo_electronico"
                                placeholder="Correo electrónico"
                                onChange={props.onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 border-right text-right">
                          Cédula
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <Input
                                className="form-control"
                                defaultValue={open.nro_documento}
                                required={true}
                                data={data2}
                                name="nro_documento"
                                placeholder="Nro documento"
                                onChange={props.onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 border-right text-right">

                        </div>
                        <div className="col">
                          <button type="submit" className="btn btn-primary mr-2">
                            Guardar datos personales
                          </button>
                          <div className="btn btn-secondary" onClick={()=>setOpen(false)}>
                            Cerrar
                          </div>
                        </div>
                      </div>
                    </form>
                    {open.perfil==='11'?<>
                      <form onSubmit={onSubmit}>
                        <div className="card mt-4">
                          <div className="card-header bg-green text-white">
                            Asignar grupos de usuarios
                          </div>
                          <div className="card-body">

                              <div className="row">
                                <div className="col-3 border-right text-right">
                                  Formulario
                                </div>
                                <div className="col">
                                  <div className="row">
                                    <div className="col">
                                      <Select
                                        defaultValue={open.ver_resultados_encuesta}
                                        required={true}
                                        data={data2}
                                        name="grupo"
                                        selectDefault="Seleccione"
                                        onChange={props.onChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <button className="btn btn-primary">Asignar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {props.lista.length>0?<>
                                <div className="row">
                                  <div className="col-12 mb-2">
                                    Grupos asignados
                                  </div>
                                  <div className="col-12">
                                    <table className="table">
                                      <thead>
                                        <th>Grupo</th>
                                        <th width="50">Acción</th>
                                      </thead>
                                      <tbody>
                                        {lista.map((row,key)=>{
                                          return  <tr key={key}>
                                                    <td>{row.grupo}</td>
                                                    <td><FontAwesomeIcon className="cursor-pointer" icon={faTrash} onClick={()=>deleteGrupo(row)}/></td>
                                                  </tr>
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </>:false}
                          </div>
                        </div>
                      </form>
                    </>:false}
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-header">
                    Gestionar fotos alumnos
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Fotografía
                        {console.log(open.url_foto)}
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <div className="row position-relative">
                              <input onChange={handleCropperModal} type="file" accept="image/*" name="userfile" className="inputfile position-absolute"/>
                              <div className="col-9 pl-4">
                                <div className="btn btn-primary">
                                  Seleccione la imagen
                                </div>
                              </div>
                            </div>
                          </div>
                          {open.url_foto!=='' && open.url_foto!=='null'?<div className="col-12 mt-3">
                            <img width="300px" src={open.url_foto} alt="pgrw"/>
                            </div>:false}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

  }

  return  <div className="container-fluid">
            <Modal modalShow={modalShow} setModalShow={setModalShow}/>
            <div className="row pt-2">
              <div className="col">
                {!open?<Table
                  onChangeQ={onChangeQ}
                  thead={thead}
                  data={data}
                  token={queryStringParams.token}
                  setOpen={setOpen}
                />:<Form  inputs={inputs}
                          setData2={setData2}
                          open={open}
                          lista={lista}
                          data2={data2}
                          onChange={onChange} />}
              </div>
            </div>
          </div>
}
export default App
