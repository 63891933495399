import { BrowserRouter, Route, Switch  } from "react-router-dom";
//import Config from "../../helpers/Config";
import StateContext from '../../helpers/ContextState';
import ListadoUsuarios from './ListadoUsuarios';
import ListadoGrupos from './ListadoGrupos';
import ListadoAlumnos from './ListadoAlumnos';
import ListadoGruposEntrenamientos from './ListadoGruposEntrenamientos';




const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/escuela/listado_usuarios"} render={()=><ListadoUsuarios/>}/>
              <Route exact path={"/sadminc/escuela/listado_grupos"} render={()=><ListadoGrupos/>}/>
              <Route exact path={"/sadminc/escuela/listado_alumnos"} render={()=><ListadoAlumnos/>}/>
              <Route exact path={"/sadminc/escuela/listado_grupos_admin"} render={()=><ListadoGruposEntrenamientos/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
