import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Store from '../../helpers/Store';
import Table from '../../screens/tablePaginadorPartidos';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import Nav from '../../screens/nav';
import { faWindowClose,faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let id=0

let colores =   {
                  Asistencia:"bg-primary text-white",
                  Inasistencia:"bg-danger text-white",
                  Excusa:"bg-warning",
                  Incapacidad:"bg-dark text-white",
                  Otros:"bg-light",
                }


let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]

let meses =   [
                {
                  label:"Febrero",
                  value:"02",
                },
                {
                  label:"Marzo",
                  value:"03",
                },
                {
                  label:"Abril",
                  value:"04",
                },
                {
                  label:"Mayo",
                  value:"05",
                },
                {
                  label:"Junio",
                  value:"06",
                },
                {
                  label:"Julio",
                  value:"07",
                },
                {
                  label:"Agosto",
                  value:"08",
                },
                {
                  label:"Septiembre",
                  value:"09",
                },
                {
                  label:"Octubre",
                  value:"10",
                },
                {
                  label:"Noviembre",
                  value:"11",
                },
                {
                  label:"Diciembre",
                  value:"12",
                },
              ]



const App=(props)=>{
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [asistencias, setAsistencias] = useState({});
  const [rangoFechas, setRangoFechas] = useState(false);
  const [mes, setMes] = useState(false);

  const getInit=()=>{
    let data        =   {
                          token:queryStringParams.token,
                          fecha:inputs.rangoFechas,
                        }
    Functions.PostAsync("Puente","Partidos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setAsistencias(data.response.asistencias)
      setTimeout(function(){
        setData(data.response.data)
        setData2(data.response.grupos)
      }, 1500);
    }
  }

  const getRangoFechas=(token)=>{
    let data            =   {token:queryStringParams.token}
        data.grupo_id   =   token
        data.mes        =   mes
    Functions.PostAsync("Puente","getIntervalFechas",data,{},{name:"callbackRangoFechas",funct:callbackRangoFechas})
  }

  const callbackRangoFechas=(data)=>{
    if (data.response.data) {
      setRangoFechas(data.response.data)
    }
    if (data.response.asistencias) {
      setAsistencias(data.response.asistencias)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  useEffect(() => {
    getRangoFechas(open.grupo_id)
  },[mes])

  useEffect(() => {

    if (!open && mes) {
      setMes(false)
      setRangoFechas(false)
      //getRangoFechas(open.grupo_id)
    }
    if (open.inscritos!==undefined) {
      setLista(open.inscritos)
    }

  },[open])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","asignarProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setAsistencias(data.response.data)
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Fecha",
        value:"fecha",
      },
      {
        label:"Contendor",
        value:"contendor",
      },
      {
        label:"Gestionar",
        value:"editar",
      },
  ]

  const asistenciaF  = (e,row,row2,keys)=>{
    let elemento = document.getElementById(keys);
        elemento.className = "text-center "+colores[e.target.value]
    let data            =   {}
        data.token      =   queryStringParams.token
        data.estatus    =   e.target.value
        data.fecha      =   row2.value
        data.alumno_id  =   row.nro_documento
    //return console.log(data);
    Functions.PostAsync("Puente","asistenciaProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const onChangeOtros  = (e,row)=>{
    let data                =   {}
        data.token          =   queryStringParams.token
        data.otros          =   e.target.value
        data.fecha          =   inputs.rangoFechas
        data.nro_documento  =   row.nro_documento
    Functions.PostAsync("Puente","asistenciaProfesorOtros",data,{})
  }

  const onChangeMes=(e)=>{

  }

  return  <><div className="container-fluid">
            <div className="row pt-2">
              <div className="col-12 mb-3 text-right">
                <a href={Config.ConfigAppUrl+"sadminc/estadisticas/partidos?token="+queryStringParams.token} className="btn btn-secondary mr-2">Volver atrás</a>
                <a href={Config.ConfigAppUrl+"sadminc/estadisticas/agregarPartidos?token="+queryStringParams.token} className="btn btn-primary">Agregar partidos</a>
              </div>
              <div className="col">
                {!open?<Table
                  thead={thead}
                  data={data}
                  setOpen={setOpen}
                />:false}
              </div>
            </div>
          </div>
        </>
}
export default App
