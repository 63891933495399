import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Input from '../../screens/inputs';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

const App=(props)=>{
  const [inputs, setInputs] = useState({});

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
    Functions.PostAsync("Puente","cambiarClave",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    alert(data.response.message)
    document.location.href  =   Config.ConfigAppUrl
  }

  return  <div className="container">
            <div className="row pt-2 justify-content-center">
              <div className="col-12 col-sm-4">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col">
                      <input
                        className="form-control"
                        type="password"
                        required={true}
                        name="clave"
                        placeholder="Clave"
                        onChange={onChange}
                        minLength="8"
                        maxLength="20"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button className="btn btn-primary btn-block" type="submit">Cambiar contraseña</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
}
export default App
