import  React,{useState,useEffect} from 'react';
import  { BrowserRouter, Route, Switch  } from "react-router-dom";
import Functions from './helpers/Functions';
import  Sadminc from "./componentes/sadminc";
import  ListaGrupos from "./componentes/escuela/ListadoGrupos";
import  ListaGruposDocumentos from "./componentes/escuela/ListadoGruposDocumentos";
import  Home from "./componentes/home";
import  Resultados from "./componentes/home/resultados";
import  Auth from "./componentes/auth";
import  logo from './assets/images/header-logo.svg';
import  queryString from 'query-string';
import  './App.css';
const   queryStringParams = queryString.parse(window.location.search);

const Defaults=()=>{
  return  <div className="App">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              Desarrollo Web  V-2021
            </header>
          </div>
}


function App() {

  const [user, setUser] = useState(false);

  const getInit=()=>{
    let data        =   {token:queryStringParams.token}
    Functions.PostAsync("Puente","tokens",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setUser(data.response.data)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  if (queryStringParams.token!==undefined && user) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} render={()=><Auth/>}/>
          <Route exact path={"/sadminc/escuela/listado_grupos"} render={()=><ListaGrupos user={user}/>}/>
          <Route exact path={"/sadminc/escuela/listado_grupos_documentos"} render={()=><ListaGruposDocumentos user={user}/>}/>
          <Route exact path={"/sadminc*"} render={()=><Sadminc user={user}/>}/>
          <Route exact path={"/encuestas"} render={()=><Home/>}/>
          <Route exact path={"/encuestasResultado"} render={()=><Resultados/>}/>
        </Switch>
      </BrowserRouter>
    );
  }else if (queryStringParams.token===undefined) {
    return <BrowserRouter>
              <Switch>
                <Route exact path={"/"} render={()=><Auth/>}/>
                <Route exact path={"/encuestas"} render={()=><Home/>}/>
                <Route exact path={"/encuestasResultado"} render={()=><Resultados/>}/>
              </Switch>
            </BrowserRouter>
  }else {
    return <Defaults/>
  }
}

export default App;
